import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1995 2400 c3 -5 19 -10 34 -10 52 0 111 -67 111 -125 0 -15 4 -24
10 -20 30 19 -4 116 -52 145 -34 20 -114 28 -103 10z"/>
<path d="M2017 2330 c43 -19 63 -44 63 -79 0 -12 5 -21 10 -21 6 0 10 16 10
35 0 50 -36 85 -87 84 l-38 0 42 -19z"/>
<path d="M1815 2316 c-23 -23 -45 -82 -45 -121 1 -40 39 -104 81 -134 53 -39
123 -43 175 -10 41 25 51 57 35 109 -13 45 -21 52 -41 40 -12 -8 -13 -13 -2
-30 16 -27 15 -69 -2 -76 -25 -9 -84 26 -130 77 -50 55 -64 95 -43 116 14 14
34 9 100 -25 4 -2 7 6 7 18 0 15 -12 26 -42 40 -55 25 -64 25 -93 -4z m70
-198 c11 -10 26 -23 33 -29 21 -18 5 -23 -28 -9 -36 15 -70 59 -70 90 0 21 1
21 22 -6 12 -16 31 -37 43 -46z"/>
<path d="M1987 2284 c-4 -4 -7 -15 -7 -25 0 -9 -16 -27 -35 -39 -38 -23 -45
-44 -18 -54 16 -6 22 -1 43 36 6 9 22 23 38 30 33 16 37 52 7 56 -12 2 -24 0
-28 -4z"/>
<path d="M1780 2065 c-6 -7 -14 -29 -17 -49 l-6 -36 87 0 c52 0 86 4 86 10 0
11 -7 15 -51 24 -15 3 -41 19 -58 35 -24 23 -33 26 -41 16z"/>
<path d="M421 1933 c-23 -21 -43 -64 -81 -170 -27 -78 -50 -144 -50 -147 0
-25 86 15 95 44 5 17 15 20 65 20 l58 0 6 -35 c8 -39 24 -44 49 -17 25 27 22
47 -28 194 -25 73 -45 134 -45 136 0 1 -9 2 -20 2 -11 0 -33 -12 -49 -27z m48
-122 c7 -23 15 -49 17 -56 4 -9 -6 -12 -36 -11 -32 0 -39 4 -34 16 3 8 10 36
16 63 13 53 19 51 37 -12z m67 -78 c29 -38 8 -57 -30 -28 -16 12 -17 17 -6 30
16 19 20 19 36 -2z m-133 -7 c4 -9 -3 -16 -16 -20 -27 -7 -33 0 -17 19 16 19
26 19 33 1z"/>
<path d="M652 1943 c-86 -42 -100 -231 -21 -297 42 -35 90 -42 145 -21 44 16
84 61 84 93 0 21 -47 13 -78 -13 -41 -35 -94 -34 -110 0 -14 31 -16 124 -2
159 17 46 90 43 105 -4 8 -24 37 -26 55 -4 21 24 1 66 -40 86 -44 22 -95 23
-138 1z"/>
<path d="M820 1950 c0 -23 50 -61 76 -58 l26 3 -1 -142 c-2 -156 1 -163 52
-123 26 20 26 25 29 142 l3 121 43 1 c39 1 42 -1 37 -19 -4 -11 -9 -46 -12
-77 -4 -45 -1 -66 16 -102 39 -79 136 -107 208 -61 44 29 63 74 63 148 0 74
-13 108 -55 147 -36 34 -125 36 -170 4 l-30 -22 -18 24 c-17 23 -23 24 -142
24 -80 0 -125 -4 -125 -10z m158 -46 c-9 -9 -21 -13 -27 -10 -20 13 -11 26 16
26 25 0 26 -1 11 -16z m279 -26 c20 -18 23 -31 23 -88 0 -78 -17 -110 -59
-110 -15 0 -35 8 -44 18 -35 39 -33 146 4 183 25 25 47 24 76 -3z"/>
<path d="M1377 1813 c1 -82 3 -151 3 -155 0 -15 40 -48 59 -48 17 0 18 6 13
65 -5 64 -4 65 19 65 20 0 30 -12 57 -65 l32 -65 44 0 45 0 -37 69 -37 70 28
28 c21 22 27 39 27 72 0 85 -40 111 -173 111 l-83 0 3 -147z m157 74 c12 -9
17 -23 14 -47 -3 -34 -4 -35 -50 -38 l-48 -3 0 50 c0 51 0 51 33 51 18 0 41
-6 51 -13z m-116 -54 c-4 -14 13 -53 23 -53 5 0 9 -4 9 -10 0 -15 -33 -50 -42
-44 -12 7 -10 114 2 114 6 0 9 -3 8 -7z"/>
<path d="M1760 1809 l0 -147 32 -27 c28 -23 39 -27 81 -22 93 8 151 85 145
190 -7 103 -55 143 -175 150 l-83 4 0 -148z m74 107 c-3 -8 -12 -17 -20 -20
-11 -4 -13 -27 -11 -110 2 -58 8 -111 13 -118 5 -8 1 -5 -8 5 -15 16 -18 38
-18 131 0 62 3 116 7 119 13 13 43 7 37 -7z m62 -30 c28 -8 44 -46 44 -102 0
-61 -22 -101 -61 -109 -17 -4 -32 -5 -34 -3 -3 3 -8 192 -6 221 1 5 9 6 19 3
9 -2 26 -7 38 -10z m93 -121 c-2 -22 -9 -49 -17 -60 -13 -19 -13 -18 -8 10 9
43 10 95 2 135 l-6 35 15 -40 c9 -22 15 -58 14 -80z"/>
<path d="M2144 1950 c-33 -13 -58 -56 -92 -160 -17 -52 -38 -112 -47 -134 -9
-21 -14 -40 -11 -43 13 -14 70 12 85 38 15 27 21 29 73 29 l57 0 7 -35 c7 -39
23 -44 49 -15 13 14 16 30 12 57 -7 53 -90 273 -102 272 -5 0 -20 -4 -31 -9z
m36 -59 c14 -27 13 -34 -5 -27 -10 3 -15 -1 -15 -13 0 -10 7 -38 15 -61 8 -23
15 -44 15 -46 0 -2 -18 -4 -41 -4 -37 0 -40 2 -33 23 26 81 32 119 19 114 -9
-4 -11 1 -8 14 7 25 39 25 53 0z m-64 -165 c7 -7 0 -15 -25 -28 -19 -10 -37
-18 -38 -18 -7 0 -2 36 9 68 l13 34 15 -23 c8 -13 20 -27 26 -33z m131 -27 l6
-33 -28 22 c-15 12 -35 22 -44 23 -11 0 -9 4 7 11 12 6 22 20 22 32 0 20 0 20
15 -1 8 -12 18 -36 22 -54z"/>
<path d="M2210 1940 c0 -25 39 -51 71 -48 l24 3 0 -142 c0 -124 2 -143 16
-143 8 0 25 10 37 23 19 20 22 36 24 142 l3 120 42 -3 c50 -4 66 17 38 48 -16
18 -32 20 -137 20 -111 0 -118 -1 -118 -20z m169 -26 c2 -2 -3 -12 -13 -23
-13 -15 -16 -35 -14 -104 1 -48 -1 -90 -5 -93 -4 -4 -7 38 -7 93 0 69 -4 103
-12 105 -7 3 -9 12 -5 22 5 13 13 16 29 10 13 -3 25 -8 27 -10z"/>
<path d="M2543 1950 c-12 -5 -29 -20 -38 -33 -19 -29 -117 -299 -111 -305 13
-13 70 13 85 39 15 27 21 29 73 29 l57 0 7 -35 c8 -47 37 -46 63 2 10 20 5 41
-30 140 -23 65 -46 130 -51 146 -10 28 -19 31 -55 17z m37 -73 c0 -10 -4 -15
-10 -12 -14 9 -12 -14 5 -71 8 -27 15 -50 15 -51 0 -2 -18 -3 -40 -3 -22 0
-40 2 -40 4 0 2 7 23 15 46 8 23 15 54 15 67 0 22 -2 24 -16 12 -14 -12 -16
-11 -13 6 5 26 50 49 61 32 4 -7 8 -20 8 -30z m55 -141 c16 -45 7 -55 -31 -35
-30 14 -31 16 -13 27 10 7 19 22 19 34 0 28 11 17 25 -26z m-138 8 c3 -9 15
-20 27 -24 20 -8 20 -9 -3 -9 -14 -1 -36 -11 -49 -23 l-25 -23 7 25 c4 14 10
35 13 48 6 26 21 29 30 6z"/>
<path d="M300 1445 l0 -115 1200 0 1200 0 0 115 0 115 -1200 0 -1200 0 0 -115z
m140 23 c-1 -29 2 -63 6 -76 6 -22 5 -23 -47 -20 l-54 3 -3 64 c-3 73 3 81 61
81 l38 0 -1 -52z m118 34 c3 -9 -5 -12 -24 -10 -28 3 -29 1 -32 -40 -3 -42 -3
-44 25 -40 20 2 28 -1 28 -12 0 -19 -51 -26 -71 -9 -18 14 -19 100 -2 117 16
16 70 11 76 -6z m72 14 c0 -2 -7 -17 -15 -32 -8 -16 -15 -37 -15 -46 0 -21
-23 -24 -38 -5 -14 16 30 87 53 87 8 0 15 -2 15 -4z m160 -8 c0 -7 -9 -24 -20
-38 -15 -19 -20 -21 -20 -9 0 9 -7 22 -15 29 -24 20 -17 30 20 30 21 0 35 -5
35 -12z m230 5 c-1 -9 -36 -53 -42 -53 -2 0 -14 14 -26 30 l-23 30 46 0 c25 0
45 -3 45 -7z m220 -8 c0 -9 -7 -18 -15 -21 -8 -4 -15 -16 -15 -27 0 -18 -4
-15 -25 15 -14 20 -25 39 -25 42 0 4 18 6 40 6 29 0 40 -4 40 -15z m170 1 c0
-7 -7 -19 -15 -26 -8 -7 -15 -20 -15 -29 0 -8 -9 1 -20 22 -11 20 -20 39 -20
42 0 3 16 5 35 5 23 0 35 -5 35 -14z m143 -56 c-1 -36 -2 -68 -3 -72 0 -5 -5
-8 -11 -8 -8 0 -10 14 -6 39 3 28 0 47 -14 67 -25 39 -24 46 9 42 27 -3 27 -4
25 -68z m147 58 c-1 -14 -34 -58 -44 -58 -3 0 -6 16 -6 35 0 31 3 35 25 35 14
0 25 -6 25 -12z m145 -18 c-8 -16 -15 -38 -15 -49 0 -11 -4 -22 -10 -26 -5 -3
-7 -13 -3 -23 3 -10 -1 -8 -12 6 -14 19 -15 29 -7 47 8 18 8 26 -4 35 -27 23
-16 40 26 40 l41 0 -16 -30z m135 19 c0 -5 4 -8 9 -4 6 3 6 -7 0 -27 -5 -18
-9 -35 -9 -38 0 -2 -9 12 -20 33 -11 20 -20 39 -20 42 0 3 9 5 20 5 11 0 20
-5 20 -11z m141 -13 c1 -6 1 -15 0 -20 -1 -4 -2 -23 -3 -42 -1 -18 -5 -31 -11
-27 -5 3 -7 19 -5 35 3 16 1 31 -4 34 -15 10 -9 44 7 38 8 -4 16 -11 16 -18z
m143 16 c6 -10 -21 -52 -33 -52 -15 0 -22 32 -10 46 13 16 35 18 43 6z m166
-6 c0 -8 -7 -16 -15 -20 -8 -3 -16 -18 -16 -33 -1 -23 -3 -24 -6 -6 -2 12 -11
27 -19 33 -26 22 -16 40 21 40 23 0 35 -5 35 -14z m225 -9 c4 -13 5 -46 3 -73
l-3 -49 -50 0 -50 0 0 47 c-1 35 -6 52 -20 61 -30 23 -8 37 55 37 54 0 60 -2
65 -23z m-605 2 c0 -7 8 -18 18 -25 16 -12 16 -13 0 -19 -22 -9 -24 -41 -3
-49 23 -9 18 -26 -9 -26 -29 0 -36 16 -36 81 0 37 4 49 15 49 8 0 15 -5 15
-11z m-1332 -57 c4 -39 -12 -62 -42 -62 -29 0 -46 18 -46 50 0 35 19 52 55 48
26 -3 30 -7 33 -36z m220 -14 c2 -38 0 -48 -12 -48 -12 0 -16 11 -16 41 0 28
-4 39 -12 36 -7 -2 -13 -19 -13 -37 0 -47 -23 -51 -27 -4 -4 53 -28 57 -28 5
0 -30 -4 -41 -15 -41 -11 0 -15 12 -15 50 l0 51 68 -3 67 -3 3 -47z m220 0 c2
-38 0 -48 -12 -48 -12 0 -16 10 -16 40 0 22 -4 40 -10 40 -5 0 -10 -18 -10
-40 0 -29 -4 -40 -15 -40 -11 0 -15 11 -15 41 0 28 -4 39 -12 36 -7 -2 -13
-19 -14 -38 0 -23 -5 -34 -14 -34 -11 0 -15 13 -16 48 l-1 48 66 -3 66 -3 3
-47z m117 12 c0 -18 6 -35 13 -37 8 -3 12 8 12 36 0 30 4 41 15 41 11 0 15
-11 15 -40 0 -22 1 -43 3 -47 3 -11 -71 -15 -77 -4 -9 13 -15 74 -9 84 11 18
28 -2 28 -33z m223 -12 c2 -38 0 -48 -12 -48 -12 0 -16 11 -16 41 0 28 -4 39
-12 36 -7 -2 -13 -19 -13 -37 0 -18 -6 -35 -12 -37 -9 -3 -13 7 -13 30 0 20
-3 43 -6 52 -6 14 0 16 37 13 l44 -3 3 -47z m122 2 c0 -38 -4 -50 -15 -50 -11
0 -15 12 -15 50 0 38 4 50 15 50 11 0 15 -12 15 -50z m160 34 c0 -10 -7 -14
-22 -12 -19 2 -23 -2 -23 -22 0 -21 4 -24 24 -22 16 3 22 -1 19 -10 -6 -18
-46 -22 -64 -8 -17 15 -19 61 -2 78 18 18 68 15 68 -4z m147 4 c6 -7 13 -29
16 -49 l5 -37 -38 0 c-41 -1 -66 18 -56 43 3 8 17 15 31 15 14 0 25 5 25 11 0
5 -10 9 -22 7 -11 -2 -23 3 -25 10 -3 8 6 12 25 12 16 0 34 -6 39 -12z m258
-39 c0 -32 -4 -44 -15 -44 -15 0 -25 55 -15 83 11 30 30 4 30 -39z m169 29
c26 -43 -31 -99 -70 -68 -14 13 -20 71 -7 84 13 12 66 2 77 -16z m164 -32 c2
-36 0 -47 -10 -44 -7 3 -14 21 -16 41 -4 50 -22 47 -22 -3 0 -59 -27 -54 -32
6 -2 25 -1 48 2 50 2 3 20 4 40 2 34 -3 35 -4 38 -52z"/>
<path d="M664 1445 c-8 -20 3 -48 16 -40 13 8 13 55 0 55 -6 0 -13 -7 -16 -15z"/>
<path d="M2302 1436 c2 -15 8 -26 13 -26 15 0 12 43 -3 48 -10 3 -13 -4 -10
-22z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
